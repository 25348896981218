/* Variables overrides */
$primary: #b6d0ce;
$secondary: #053331;
$tertiary: $secondary;
$border-color: lighten(#b1d0d3, 10%);

$body-color: $tertiary;
$headings-color: $tertiary;
$headings-color-detail: $headings-color;
$link-color: $tertiary;
$breadcrumb-text-color: lighten($secondary, 10%);

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$body-bg: #f7f7f7;

$search-home-bg-color: $primary;
$search-overview-bg-color: $border-color;

$mobile-header-background: white;
$mobile-header-color: $primary;

$navbar-dark-color: $primary;
$navbar-dark-active-color: $link-color;
$navbar-dark-hover-color: $link-color;

$mobile-header-background: $secondary;
$mobile-header-color: $white;

$bottom-footer-bg-color: #222;

$border-radius: 4px;

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
$font-family-sans-serif: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-base: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$btn-font-family: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$headings-font-family: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

/* Extra overrides */
#header > .header.header-top-strip .top {
  background: $secondary;
  @include media-breakpoint-up(md) {
    background: url(../images/wood.jpg) repeat;
  }
}
@include media-breakpoint-up(md) {
  #header > .header a {
    color: $secondary;
    font-weight: 700;
  }
}

@include media-breakpoint-up(xl) {

  #header > .header.header-top-strip .main .container .logo img {
    max-height: 100px;
    max-width: 160px;
  }
}
#header > .header nav .nav-link {
  font-size: 1.125rem;
}
.node-type-overview-page #block-helper-search-search-holiday-homes h2 {
  color: $secondary;
  font-weight: 700;
}

.btn-primary {
  background: $secondary;
  border-color: $secondary;
}

.btn-outline-primary {
  color: $secondary;
  border-color: $secondary;
}

.discounts-custom {
  .inner {
    > div {
      position:absolute;
      top: -7px;
      left:-10px;
      height:73px;
      width:193px;
      background-size:contain !important;
      background-repeat:no-repeat !important;
      z-index:0;
      .column &{
        top:65px;
      }
      @include media-breakpoint-down(lg){
        width:193px;
        top: -7px;
        left:-10px;
        .column &{
          top:5px;
          left:auto;
          right:5px;
          width:120px;
        }
      }
      &.nl{
        background:url(../images/aanbieding.png);
      }
      &.de{
        background:url(../images/angebot.png);
      }
      &.last_minute.de,
      &.last_minute.nl{
        background:url(../images/last-minute.png);
      }
    }
  }
}
